@keyframes ldio-9ro5rlpgzop-o {
    0%    { opacity: 1; transform: translate(0 0) }
   49.99% { opacity: 1; transform: translate(53.04px,0) }
   50%    { opacity: 0; transform: translate(53.04px,0) }
  100%    { opacity: 0; transform: translate(0,0) }
}
@keyframes ldio-9ro5rlpgzop {
    0% { transform: translate(0,0) }
   50% { transform: translate(53.04px,0) }
  100% { transform: translate(0,0) }
}

.loader{
    text-align: center;
    margin: 0 auto;
}
.ldio div {
  position: absolute;
  width: 53.04px;
  height: 53.04px;
  border-radius: 50%;
  top: 75.48px;
  left: 48.96px;
}
.ldio div:nth-child(1) {
  background: #1d3f72;
  animation: ldio-9ro5rlpgzop 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio div:nth-child(2) {
  background: #5699d2;
  animation: ldio-9ro5rlpgzop 1s linear infinite;
  animation-delay: 0s;
}
.ldio div:nth-child(3) {
  background: #1d3f72;
  animation: ldio-9ro5rlpgzop-o 1s linear infinite;
  animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball {
  width: 204px;
  height: 204px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio div { box-sizing: content-box; }
/* generated by https://loading.io/ */