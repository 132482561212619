.grid-container {
  margin: 10px;
}

.image-grid {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-auto-rows: minmax(30px, 400px);
}


.image {
    background: rgba(2,0,36,1);
    padding: 3px;
    border-radius: 3px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55) transform;
  }
  
  .image:hover {
    transform: translateY(-2px);
  }
  
  .image img {
    display: flex;
    width: 100%;
    height: 85%;
    object-fit: cover;
  }
  
  .image-footer {
    height: 15%;
    color: whitesmoke;
    padding: 0.8rem;
    display: flex;
    flex: 0.6 0.4;
    align-content: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  
  .image-footer-title {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 65%;
  }

  .image-footer-title-link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    color: whitesmoke;
    height: 100%;
    width: 100%;
  }

  .image-footer-title-link:hover {
    color: white;
  }

  .image-footer-user {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
  }

  .image-footer-user-link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: whitesmoke;
    height: 100%;
    width: 100%;
  }

  .image-footer-user-link:hover {
    color: white;
  }

  @media only screen and (max-width: 700px){
    .grid-container{
      margin: 30px 10px 10px 10px;
    }
    .image:nth-child(3n) {
      grid-row-end: span 2;
    }
  }