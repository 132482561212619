@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");

.form {
  width: 50%;
  margin-top: 1rem;
  margin-bottom: -50px;
  margin-left: -5rem;
}

.license-container {
  padding: 1.5rem;
}

.license {
  word-wrap: break-word;
  font-size: 14px;
  color: rgb(179, 175, 175);
  font-family: "Lato", sans-serif;
}

.license a {
  text-decoration: none;
  color: rgb(228, 222, 222);
  font-weight: 900;
}

.license a:hover {
  text-decoration: none;
  color: white;
  font-weight: 900;
}

.search-container {
  text-align: center;
  width: 100%;
  margin: 2rem 0 4rem 0;
}

@media only screen and (max-width: 480px) {
  .form {
    width: 60%;
    margin-left: -5rem;
  }
  .search-container {
    margin: 1rem 0 2rem 0;
  }
}

@media only screen and (max-width: 600px) {
  .form {
    width: 65%;
    margin-left: -5rem;
  }
  .search-container {
    margin: 2rem 0 3rem 0;
  }
}
