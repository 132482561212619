@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@800;900&display=swap);
@-webkit-keyframes ldio-9ro5rlpgzop-o {
    0%    { opacity: 1; -webkit-transform: translate(0 0); transform: translate(0 0) }
   49.99% { opacity: 1; -webkit-transform: translate(53.04px,0); transform: translate(53.04px,0) }
   50%    { opacity: 0; -webkit-transform: translate(53.04px,0); transform: translate(53.04px,0) }
  100%    { opacity: 0; -webkit-transform: translate(0,0); transform: translate(0,0) }
}
@keyframes ldio-9ro5rlpgzop-o {
    0%    { opacity: 1; -webkit-transform: translate(0 0); transform: translate(0 0) }
   49.99% { opacity: 1; -webkit-transform: translate(53.04px,0); transform: translate(53.04px,0) }
   50%    { opacity: 0; -webkit-transform: translate(53.04px,0); transform: translate(53.04px,0) }
  100%    { opacity: 0; -webkit-transform: translate(0,0); transform: translate(0,0) }
}
@-webkit-keyframes ldio-9ro5rlpgzop {
    0% { -webkit-transform: translate(0,0); transform: translate(0,0) }
   50% { -webkit-transform: translate(53.04px,0); transform: translate(53.04px,0) }
  100% { -webkit-transform: translate(0,0); transform: translate(0,0) }
}
@keyframes ldio-9ro5rlpgzop {
    0% { -webkit-transform: translate(0,0); transform: translate(0,0) }
   50% { -webkit-transform: translate(53.04px,0); transform: translate(53.04px,0) }
  100% { -webkit-transform: translate(0,0); transform: translate(0,0) }
}

.loader{
    text-align: center;
    margin: 0 auto;
}
.ldio div {
  position: absolute;
  width: 53.04px;
  height: 53.04px;
  border-radius: 50%;
  top: 75.48px;
  left: 48.96px;
}
.ldio div:nth-child(1) {
  background: #1d3f72;
  -webkit-animation: ldio-9ro5rlpgzop 1s linear infinite;
          animation: ldio-9ro5rlpgzop 1s linear infinite;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.ldio div:nth-child(2) {
  background: #5699d2;
  -webkit-animation: ldio-9ro5rlpgzop 1s linear infinite;
          animation: ldio-9ro5rlpgzop 1s linear infinite;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.ldio div:nth-child(3) {
  background: #1d3f72;
  -webkit-animation: ldio-9ro5rlpgzop-o 1s linear infinite;
          animation: ldio-9ro5rlpgzop-o 1s linear infinite;
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.loadingio-spinner-dual-ball {
  width: 204px;
  height: 204px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transform: translateZ(0) scale(1);
          transform: translateZ(0) scale(1);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0; /* see note above */
}
.ldio div { box-sizing: content-box; }
/* generated by https://loading.io/ */
.end {
  text-align: center;
  margin: 0 auto;
  padding: 2rem 0 2rem 0;
}

.message {
  font-family: 'Orbitron', sans-serif;
  font-size: 5rem;
  text-align: center;
  color: whitesmoke;
}
.grid-container {
  margin: 10px;
}

.image-grid {
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-auto-rows: minmax(30px, 400px);
}


.image {
    background: rgba(2,0,36,1);
    padding: 3px;
    border-radius: 3px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55) -webkit-transform;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55) transform;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55) transform, 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55) -webkit-transform;
  }
  
  .image:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  
  .image img {
    display: flex;
    width: 100%;
    height: 85%;
    object-fit: cover;
  }
  
  .image-footer {
    height: 15%;
    color: whitesmoke;
    padding: 0.8rem;
    display: flex;
    flex: 0.6 0.4;
    align-content: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  
  .image-footer-title {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 65%;
  }

  .image-footer-title-link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-weight: bold;
    color: whitesmoke;
    height: 100%;
    width: 100%;
  }

  .image-footer-title-link:hover {
    color: white;
  }

  .image-footer-user {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 30%;
  }

  .image-footer-user-link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: whitesmoke;
    height: 100%;
    width: 100%;
  }

  .image-footer-user-link:hover {
    color: white;
  }

  @media only screen and (max-width: 700px){
    .grid-container{
      margin: 30px 10px 10px 10px;
    }
    .image:nth-child(3n) {
      grid-row-end: span 2;
    }
  }
.form {
  width: 50%;
  margin-top: 1rem;
  margin-bottom: -50px;
  margin-left: -5rem;
}

.license-container {
  padding: 1.5rem;
}

.license {
  word-wrap: break-word;
  font-size: 14px;
  color: rgb(179, 175, 175);
  font-family: "Lato", sans-serif;
}

.license a {
  text-decoration: none;
  color: rgb(228, 222, 222);
  font-weight: 900;
}

.license a:hover {
  text-decoration: none;
  color: white;
  font-weight: 900;
}

.search-container {
  text-align: center;
  width: 100%;
  margin: 2rem 0 4rem 0;
}

@media only screen and (max-width: 480px) {
  .form {
    width: 60%;
    margin-left: -5rem;
  }
  .search-container {
    margin: 1rem 0 2rem 0;
  }
}

@media only screen and (max-width: 600px) {
  .form {
    width: 65%;
    margin-left: -5rem;
  }
  .search-container {
    margin: 2rem 0 3rem 0;
  }
}

.container {
    text-align: center;
    height: 8rem;
    padding-top: 1rem;
}

.heading {
    word-wrap: break-word;
    font-family: 'Orbitron', sans-serif;
    font-size: 5rem;
    color: whitesmoke;
}


@media only screen and (max-width: 600px) {
    .container{
        height: 5rem;
    }

    .heading{
        font-size: 3rem;
    }
}
* {
    box-sizing: border-box;
  }
  
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
  }

  body {
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(44,45,50,1) 35%, rgba(9,35,41,1) 100%) !important;
  }
